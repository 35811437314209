/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----   ITEM CAROUSEL    ITEM CAROUSEL    ITEM CAROUSEL    ITEM CAROUSEL   ----/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist {
  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .items-carousel-list {
        .slick-list {
          .item-carousel {
            .item {
              .options {
                background-color: darken(rgba($color, 0.9), 40%);
                li {
                  a {
                    color: $color;
                  }
                }
              }
            }
            &.selectable {
              &.selected {
                .item {
                  background-color: darken(rgba($color, 0.65), 40%) !important;
                }
              }
              &:before,
              &:after {
                border: solid 2px $color;
              }
              .selection-mask {
                &:before,
                &:after {
                  border: solid 2px $color;
                }
              }
            }
          }
        }
        .arrow {
          color: $color;
        }
      }
    }
  }

  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .items-carousel-list {
        .arrow {
          &:hover {
            color: $color;
          }
          &.next {
            &:after {
              background-color: $color;
            }
          }
          &.prev {
            &:after {
              background-color: $color;
            }
          }
        }
      }
    }
  }

  .items-carousel-list {
    .slick-list {
      .item-carousel {
        border: solid 1px #6a676e;
        background: linear-gradient(to right, #ffffff 0%, #6a676e 30%) no-repeat,
          linear-gradient(0deg, #ffffff 0%, #6a676e 30%) no-repeat,
          linear-gradient(to right, #6a676e 70%, #ffffff 100%) no-repeat,
          linear-gradient(180deg, #ffffff 0%, #6a676e 30%) no-repeat;
        background-position: top left, top right, bottom left, top left;
        background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;

        .item {
          background-color: rgba(255, 255, 255, 0.1);

          .row {
            .stats {
              > div {
                border: solid 1px #6a676e;
                background: linear-gradient(to right, #ffffff 0%, #6a676e 30%)
                    no-repeat,
                  linear-gradient(0deg, #ffffff 0%, #6a676e 30%) no-repeat,
                  linear-gradient(to right, #6a676e 70%, #ffffff 100%) no-repeat,
                  linear-gradient(180deg, #ffffff 0%, #6a676e 30%) no-repeat;
                background-position: top left, top right, bottom left, top left;
                background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
              }
            }
          }

          .options {
            li {
              a {
                &:hover {
                  color: white;
                }
              }

              + li {
                border-left: solid 2px rgba(0, 0, 0, 0.27);
                box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.27);
              }
            }
          }
        }

        &.selectable,
        &.selectable:hover {
          border-color: #6a676e;
        }
      }
    }
  }
}
