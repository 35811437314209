/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/--  BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR  ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.bottombar {
  height: 40px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100;

  &:hover {
    .mass-form-delete {
      bottom: -18px;
    }
  }

  .views {
    margin-left: -5px;
    padding: 3px 0;
    float: left;

    li {
      display: inline-block;
      font-size: 26px;

      + li {
        padding-left: 8px;
        margin-left: 8px;
      }
    }
  }

  .mass-form-delete {
    width: 70px;
    height: 40px;
    position: absolute;
    background: none;
    border: none;
    left: 0;
    right: 0;
    bottom: -40px;
    margin: auto;
    font-size: 30px;
    transition: .3s;

    &:hover {
      bottom: 0;
    }

    &.selected {
      bottom: 0;
    }

    .amount {
      font-size: 16px;
      position: relative;
      top: -6px;
    }
  }

  .create-new-form {
    float: right;
    position: relative;
    padding-right: 70px;
    margin-top: -6px;

    .text {
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      letter-spacing: 1px;
    }

    .icon-arrow {
      display: inline-block;
      vertical-align: middle;
      font-size: 50px;
    }

    #add-form {
      width: 84px;
      height: 84px;
      position: absolute;
      top: -33px;
      right: -15px;
      border-radius: 50%;
      padding: 15px;

      span {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        display: block;
        text-align: center;
        font-size: 30px;
        padding: 8px 0;
        border-top: solid 4px rgba(255, 255, 255, .4);
      }
    }
  }

  .go-back {
    padding: 6px 0;
    float: right;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .icon-chevron-left {
      font-size: 56px;
      margin-right: -15px;
      line-height: 0;
    }
  }
}