body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.wiki-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8vh;
  margin-left: 0;
  overflow-y: hidden;
  color: #000 !important;
  box-sizing: initial !important;
}

.summary-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: "Nunito";
  width: 15vw;
  height: 92vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: none;
  border-radius: 0px 10px 10px 0px;
  background-image: linear-gradient(
    to top,
    #c9e5f6,
    #d8ebf7,
    #deeef85f,
    #fcfcfc
  );
  box-shadow: 2px 2px 5px 2px #00000066;
}

.summary-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.summary {
  width: 12vw;
  list-style: none;
  padding: 2vw;
  padding-top: 4vh;
  margin: 0;
}

.wiki-body {
  width: 80%;
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1vw;
  padding-left: 3vw;
  padding-top: 0;
}

.code-tester-container {
  display: flex;
  flex-direction: column;
  background-color: #333235;
  padding: 0px;
  font-family: "monospace";
  border-radius: 10px;
  margin-top: 3vh;
  margin-bottom: 0vh;
  margin-left: 15vw;
  margin-right: 10vw;
  height: fit-content;
  max-width: 50vw;
}

.code-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #bbbbbb;
  border-radius: 5px 5px 0px 0px;
  align-content: center;
}

.code-header-label {
  padding: 5px;
  font-family: "Nunito";
  margin: 0 !important;
}

.header-actions {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0vh;
  left: 95vw;
}

.code-header-btn {
  display: flex;
  flex-direction: row;
  position: sticky;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Nunito";
}

.code-header-btn img {
  width: 20px;
  height: 20px;
}

.code-header-btn:hover {
  cursor: pointer;
  font-family: "Nunito-Black";
}

.code {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 0;
  border-radius: 5px;
  background-color: #333235;
}

.code-input,
.code-command {
  background-color: #333235;
  border: 0;
  width: 48vw;
  resize: none;
  margin: 0;
  outline: none;
  color: #fff;
  font-family: monospace;
  font-size: 1.2vw;
  overflow-y: auto;
  white-space: pre;
  resize: none;
}

.code-footer {
  display: flex;
  flex-direction: row;
  position: relative;
  border-radius: 5px;
}

.section {
  font-family: "Nunito-Black";
  font-size: 1.5vw;
  margin: 0;
  margin-bottom: 1vh;
}

.section-elem {
  margin: 0;
  margin-bottom: 2vh;
  border-radius: 5px;
  padding: 3px;
  background-color: transparent;
  font-size: 1.3vw;
  transition: background-color 0.1s ease-in-out;
}

.summary a {
  color: #000 !important;
}

.section-elem:hover {
  cursor: pointer;
  background-color: #e7e6e655;
}

.section-elem.selected {
  padding-left: 7px;
  background-color: #bbbbbb;
}

.after-before {
  position: relative;
  padding-bottom: 7vh;
  background-color: transparent;
  border: none;
}

.line {
  display: flex;
  height: 0.2vh;
  background-color: #bbbbbb;
}

.grammar-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: 3vh;
}

.grammar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: monospace;
  font-size: 1.3vw;
  background-color: #bbbbbb;
  border-radius: 5px;
  padding: 1vw;
  min-width: 40vw;
  width: fit-content;
}

.sub-grammar {
  margin-bottom: 2vh;
}

.grammar-children {
  padding-left: 20px;
}

.grammar-children p {
  margin-top: 0;
  margin-bottom: 0;
}

.invisible {
  width: 0.5vw;
}

.invisible-bottom {
  height: 40vh;
}

.wiki-title {
  margin: auto;
  margin-top: 4vh;
  margin-bottom: 1vh;
  font-size: 4vw;
  font-family: "Nunito-Black";
}

.wiki-subt {
  margin: 0;
  cursor: pointer;
  font-size: 2vw;
  font-family: "Nunito";
}

.wiki-text {
  margin: auto;
  margin-top: 1vh;
  font-size: 1.25vw;
  text-align: justify;
  font-family: "Nunito";
}

.quote-container {
  display: flex;
  flex-direction: column;
  background-color: #bbbbbb72;
  font-family: "Nunito";
  font-size: 1.2vw;
  font-style: italic;
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding: 3vh;
  border-radius: 0.5vw;
  margin-left: 13vw;
  margin-right: 7vw;
  width: 50vw;
}

.quote-text {
  margin: 0;
}

.quote-author {
  margin: 0;
  align-self: flex-end;
}

.quote-wrapper {
  display: flex;
  align-items: center;
}
.quote-bar {
  width: 0.2vw;
  background-color: #000;
  height: 3.5vw;
  margin-right: 10px;
}

.show-message {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: static;
  padding: 0.5vw;
  background-color: #333235;
  height: fit-content;
  width: 47vw;
  border-radius: 0.5vw;
  font-family: monospace;
  font-size: 15px;
  color: #fff;
  text-align: left;
  justify-content: left;
  overflow-y: auto;
}

.response-message {
  margin: 0;
  padding: 0;
  break-before: none;
}

.elems-section {
  transition: opacity 0.3s ease-in-out, max-height 0.1s ease-in-out 0.1s;
}

.elems-section.show {
  opacity: 1;
  position: relative;
  max-height: 3000px;
  z-index: 0;
}

.elems-section.hide {
  position: relative;
  max-height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: -1;
}

.section-title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin: 0;
}

.arrow {
  height: 2vw;
  width: 1.5vw;
  margin: 0.6vw;
  padding: 0.2vw;
  transition: transform 0.3s ease-in-out;
}

.arrow.rotate {
  transform: rotate(90deg);
}

.arrow:hover {
  cursor: pointer;
  background-color: #bbbbbb8b;
  border-radius: 0.3vw;
}

.wiki-section {
  margin-top: 0vh;
}

.index-container {
  display: inline-block;
}

.index {
  float: left;
  font-family: "Nunito";
  font-size: 1.3vw;
  border: 3px solid;
  border-color: #bbbbbb;
  width: fit-content;
  border-radius: 1vw;
  box-shadow: 2px 2px 5px 1px #00000066;
  max-height: fit-content;
  min-width: 20vw;
  margin-bottom: 2vh;
  margin-right: 2vw;
  padding-right: 4vw;
  padding-bottom: 1vh;
}

.index-title {
  font-family: "Nunito-Black";
  padding-left: 1vw;
  padding-top: 0;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

.index-elem-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 2vw;
}

.index-elem-container img {
  height: 1vw;
  width: 1vw;
  border-radius: 0.3vw;
  padding: 1px;
  cursor: pointer;
}

.index-elem {
  margin: 0.5vh;
  text-decoration: underline;
  cursor: pointer;
}

.index-elem:hover {
  color: #bbbbbb;
}

.go-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 3vh;
  right: 3vw;
  width: 3.5vw;
  height: 3.5vw;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #bbbbbb;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
}
.go-top img {
  width: 3vw;
  height: 2vw;
  padding: 2px;
}

.go-top.show {
  opacity: 0.8;
}

.go-top.hide {
  opacity: 0;
  pointer-events: none;
}

.wiki-text ul {
  margin-top: 0;
}
