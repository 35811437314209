/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---  ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX  ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist {

  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .item-box {
        .item {
          &:hover {
            border: solid 1px darken(rgba($color,.5), 30%);
            background: linear-gradient(to right, $color, darken(rgba($color,.5), 30%)) no-repeat,
            linear-gradient(to bottom, darken(rgba($color,.5), 30%), $color) no-repeat,
            linear-gradient(to left, $color, darken(rgba($color,.5), 30%)) no-repeat,
            linear-gradient(to top, darken(rgba($color,.5), 30%), $color) no-repeat;
            background-position: top left, top right, bottom left, top left;
            background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
          }
          .options {
            background-color: darken(rgba($color, .65), 40%);
            li {
              a {
                color: $color;
              }
            }
          }
        }
        &.selectable {
          .item {
            &:before, &:after {
              border: solid 2px $color;
            }
            .selection-mask {
              &:before, &:after {
                border: solid 2px $color;
              }
            }
            &.selected {
              .body, .footer {
                background-color: darken(rgba($color, .65), 40%);
              }
            }
          }
        }
      }
    }
  }

  //Basic Colors
  .item-box {
    .item {
      border: solid 1px #6A676E;
      background: linear-gradient(to right, #ffffff, #6A676E) no-repeat,
      linear-gradient(to bottom, #6A676E, #ffffff) no-repeat,
      linear-gradient(to left, #ffffff, #6A676E) no-repeat,
      linear-gradient(to top, #6A676E, #ffffff) no-repeat;
      background-position: top left, top right, bottom left, top left;
      background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;

      .body {
        background-color: rgba(255, 255, 255, .2);

        [class^="icon-"], [class*=" icon-"] {
          color: rgba(0, 0, 0, .1);
        }
      }

      .footer {
        border-top: solid 1px #797B81;
        background-color: rgba(255, 255, 255, .2);

        span {
          &:first-child {
            box-shadow: inset -2px 0 0 rgba(0,0,0,.27);
          }

          &:last-child {
            box-shadow: inset 1px 0 0 rgba(255,255,255,.27);
          }
        }
      }

      .options {
        li {
          a {
            &:hover {
              color: white;
            }
          }

          &:nth-child(2), &:nth-child(4) {
            border-left: solid 2px rgba(0,0,0,.27);
            box-shadow: inset 1px 0 0 rgba(255,255,255,.27);
          }

          &:nth-child(3), &:nth-child(4) {
            border-top: solid 2px rgba(0,0,0,.27);
            box-shadow: inset 0 1px 0 rgba(255,255,255,.27);
          }

          &:nth-child(4) {
            box-shadow: inset 1px 1px 0 rgba(255,255,255,.27);
          }
        }
      }
    }

    &.selectable {
      .item, .item:hover {
        background: none;
        border-style: dotted;
        border-color: #6A676E;
      }
    }
  }
}