//COLORS
//------------------------------------------------------------------------------

$color1: white;
$color2: #333333;
$color3: #73dddb;
$color4: #874a4a;
$color5: #633636;

//backgrounds
$bg-color1: $color1;
$bg-color3: $color3;
$bg-color4: $color4;
$bg-color5: $color5;

//text
$text-color1: $color1;
$text-color2: $color2;
$text-color3: $color3;

//FONTS FAMILY
//------------------------------------------------------------------------------

$font1: "Roboto Condensed", sans-serif;

//MODULES CONFIG
//------------------------------------------------------------------------------

//base styles
$body-font: $font1;
$body-font-size: 16px;
$body-color: $text-color1;

//headings
$h1-size: 28px;
$h2-size: 20px;
$h3-size: 18px;
$h4-size: 18px;
$h5-size: 16px;
$h6-size: 14px;
$headings-font: $font1;
$headings-color: $text-color1;

//buttons
$btn-color: $text-color1;
$btn-color-hover: $text-color3;
$btn-radius: 3px;
$btn-padding: 7px 10px;
$btn-bg: #020407;

$btn-bg-primary: $bg-color3;
$btn-bg-primary-hover: #ff5e54;

$btn-bg-secondary: $bg-color4;
$btn-bg-secondary-hover: $bg-color5;

//badges
$badge-color: #0a101a;
$badge-bg: #ff5e54;

//forms
$input-bg: #111218;
$input-border-radius: 3px;
$input-font-family: $font1;

//tooltip
$tooltip-font-family: $font1;
