/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    FORMS    FORMS    FORMS     FORMS     FORMS     FORMS     FORMS    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear .form-control {
  background-color: #d9d4cf;
  border: solid 1px #d9d4cf;
  border-top: solid 1px #f5f0ec;
  border-bottom: solid 2px #c3bfba;

  &::-webkit-input-placeholder {
    color: white;
  } /* WebKit */
  &:-moz-placeholder {
    color: white;
  } /* Firefox 18- */
  &::-moz-placeholder {
    color: white;
  } /* Firefox 19+ */
  &:-ms-input-placeholder {
    color: white;
  } /* IE 10+ */
}

.clear .input-group {
  background: none;

  .input-group-addon {
    color: white;
  }
}

.clear.degraded {
  .ref-title {
    background-color: #e7ebe5;
  }
}
.clear.plain {
  .ref-title {
    background-color: #f1ece6;
  }
}
