/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    BUTTONS    BUTTONS    BUTTONS    BUTTONS    BUTTONS    BUTTONS     ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {
  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .btn {
        &:hover,
        &:focus {
          color: $color;
        }
        &.btn-primary {
          background-color: $color;
        }
        &.btn-primary-2 {
          background-color: darken($color, 20%);
          color: white;

          &:hover {
            background-color: darken($color, 30%);
          }
        }
      }
    }
  }

  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .btn {
        &.btn-primary {
          &:hover {
            background-color: $color;
          }
        }
      }
    }
  }

  .btn {
    color: white;
    background-color: #020407;

    &.btn-primary {
      color: #0a101a;

      &:hover {
        color: #0a101a;
      }
    }

    &.btn-secondary {
      background-color: #874a4a;
      color: white !important;

      &:hover {
        background-color: #633636;
      }
    }
  }
}
