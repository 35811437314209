/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----     BASE STYLES      BASE STYLES      BASE STYLES      BASE STYLES    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist {
  color: white!important;
  background-color: #0A101A;
}