.forms-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8vh;
  margin-left: 0;
  text-align: center;
  justify-content: center;
  width: 90vw;
  overflow-y: auto;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito-Black";
  min-width: 10vw;
  height: 15vh;
  margin: 2vw;
  border-radius: 0.5vw;
  box-shadow: 2px 2px 10px 2px #bbbbbb;
  background-image: linear-gradient(to bottom, #c9e5f6, #d8ebf7, #deeef85f);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.form:hover {
  transform: scale(1.1);
}

.no-forms-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: center;
  justify-content: center;
  margin: auto;
  margin-top: 15vh;
  font-family: inherit;
}

.no-forms-title {
  font-size: 5vw;
  color: #333;
  padding: 2vw;
  padding-top: 0;
  padding-bottom: 0;
  margin: auto;
  margin-bottom: 0px;
}

.no-forms-text {
  font-size: 2vw;
  width: 100vw;
  color: #484848;
  margin-bottom: 0px;
}

.fieldset-clear {
  font-size: 1.5vw;
  color: white;
}

.fieldset-dark {
  color: #000;
}
