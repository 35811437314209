/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    BADGES    BADGES    BADGES    BADGES    BADGES    BADGES     ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {
  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .badge {
        background-color: $color;
      }
    }
  }

  .badge {
    color: #0a101a;
  }
}
