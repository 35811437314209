/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----   FONTS     FONTS     FONTS     FONTS     FONTS     FONTS     FONTS   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */

//@import url('https://fonts.googleapis.com/css?family=Kalam');

@font-face {
  font-family: "Kalam", cursive;
  src: url(../../fonts/Kalam-Regular.ttf);
}
