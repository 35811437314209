/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    BADGES    BADGES    BADGES    BADGES    BADGES    BADGES     ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.badge {
  padding: 0 5px;
  border-radius: 25px;
  display: inline-block;
  font-weight: 700;
}