/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    FORMS    FORMS    FORMS     FORMS     FORMS     FORMS     FORMS    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.form-control {
  height: 35px;
  box-shadow: none;
  border-radius: $input-border-radius;
  border-top: none;
  font-family: $input-font-family;
  font-style: italic;
}

.input-group {
  position: relative;
  padding-top: 1px;
  border-radius: 3px;

  .input-group-addon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 26px;
    background: none;
    border: none;
  }

  .form-control {
    padding-right: 36px;
  }
}

.ref-group {
  display: flex;
  flex-direction: column;
  border-top: 3px solid gray;
  border-left: 3px solid gray;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  border-radius: 10px;
  height: fit-content;
  min-width: 60%;
  width: fit-content;
  margin-bottom: 10px;
  padding: 10px;

  table {
    width: fit-content;

    th {
      text-align: center;
    }
    th,
    td {
      padding-right: 2.5vw;
      padding-right: 2.5vw;
    }
  }
  .add-row {
    position: relative;
    width: fit-content;
    height: fit-content;
    left: 0%;
    margin: 0.5%;
    border: #cacaca 2px solid;
    border-bottom: #000000 2px solid;
    border-right: #000000 2px solid;
    border-radius: 100%;
    color: #000000;
    cursor: pointer;

    span {
      display: block;
      height: 3%;
      width: 3%;
    }
    &.disabled {
      cursor: not-allowed;
      color: #cacaca;
    }
  }
}

.ref-title {
  position: relative;
  top: 13px;
  left: 20px;
  padding: 3px;
}

.image-field {
  img {
    width: inherit;
    height: inherit;
    border-radius: 10px;
  }
  display: flex;
  flex-direction: row;
  width: inherit;
  height: inherit;
  overflow: hidden;
}

.input-ps-cn {
  &.file {
    display: none;
  }
}
.input-ps-cn {
  &.file + label {
    display: flex;
    flex: 1;
    width: 200px;
    margin-bottom: 0;
  }
  margin-right: 10px;
}
.file-input-name,
.file-input-button {
  padding: 5px 10px;
}

.file-input-name {
  background: #eee;
  border: 2px solid #cacaca;
  flex: 1;
  border-radius: 4px 0 0 4px;
}

.file-input-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  display: block;
}

.file-input-button {
  display: flex;
  background-color: transparent;
  border: 1px solid $color2;
  white-space: nowrap;
  border-radius: 0 4px 4px 0;
  padding-left: 10px;
  justify-content: center;
  cursor: pointer;
}
