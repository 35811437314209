//COLORS
//------------------------------------------------------------------------------

$color1: #73dddb;

$workspace-color1: #c6b186;

$workspace-color2: #ccac63;

$workspace-color3: #527974;

$workspace-color4: #5c6375;

$workspace-color5: #7d514f;
