/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----  TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR  ----/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist .topbar {
  background-color: #0a101a;

  .logo {
    svg {
      .fil0 {
        fill: #999999;
      }
      .fil1 {
        fill: white;
      }
    }
  }

  .user {
    .dropdown-menu {
      background-color: rgba(10, 16, 26, 0.9);
      box-shadow: inset 0 3px 0 #ffffff;
      border-left: solid 1px #5b595c;
      border-right: solid 1px #5b595c;
      border-bottom: solid 3px rgba(0, 0, 0, 0.9);

      &:before {
        @include triangle-top-right(5px, 5px, 5px, 5px, white);
      }

      img {
        background-color: white;
        border: solid 1px white;
      }

      p {
        color: white;

        small {
          color: #5b595c;
        }
      }

      .dropdown-menu-footer {
        border-top: solid 2px rgba(0, 0, 0, 0.9);
        box-shadow: inset 0 1px 0 #525052;
      }
    }
  }
  .skin {
    .dropdown-menu {
      background-color: rgba(10, 16, 26, 0.9);
      box-shadow: inset 0 3px 0 #ffffff;
      border-left: solid 1px #5b595c;
      border-right: solid 1px #5b595c;
      border-bottom: solid 3px rgba(0, 0, 0, 0.9);

      &:before {
        @include triangle-top-right(5px, 5px, 5px, 5px, white);
      }

      img {
        background-color: white;
        border: solid 1px white;
      }

      p {
        color: white;

        small {
          color: #5b595c;
        }
      }
      .dropdown-menu-option {
        color: white;
      }

      .dropdown-menu-footer {
        border-top: solid 2px rgba(0, 0, 0, 0.9);
        box-shadow: inset 0 1px 0 #525052;
      }
    }
  }
}
