/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---  MARKERS   MARKERS   MARKERS   MARKERS   MARKERS   MARKERS   MARKERS   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear .markers {
  .marker {
    border-right: solid 3px #A7A5A1;

    p {
      span {
        background-color: #A7A5A1;
        color: white;
      }
    }
  }
}