//COLORS
//------------------------------------------------------------------------------

$main-colors: (
  main-color1: #30aeac,
  main-color2: #2c9974,
  main-color3: #993366,
  main-color4: #cf2013,
  main-color5: #5636a1,
);

$secondary-colors: (
  secondary-color1: #ff5e54,
  secondary-color2: #ffa600,
  secondary-color3: #33cc33,
  secondary-color4: #4477dd,
  secondary-color5: #ed129a,
);
