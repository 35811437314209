/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    BADGES    BADGES    BADGES    BADGES    BADGES    BADGES     ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.vintage .badge {
  color: white;
  background-color: #FF5E54;
}