.header {
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    #c9e5f6,
    #d8ebf7,
    #deeef8a2,
    #fcfcfc50
  );
  height: 4vw;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 200;
}

.header label {
  cursor: pointer;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  float: right;
  padding: auto;
  padding-top: 1vh;
  margin: auto;
  border-radius: 20px;
}

.top-items {
  border-radius: 20px;
  box-shadow: 1px 1px 1px 1px grey;
  height: 2vw;
  width: 2vw;
  margin-right: 1vw;
  background-color: #eeeeee52;
  transition: transform 0.3s ease-in-out;
}

.top-items:hover {
  transform: scale(0.9);
  cursor: pointer;
}

.top-logo {
  height: 3.5vw;
  width: 3.5vw;
  margin-right: 1vw;
}

.title-logo {
  display: flex;
  flex-direction: row;
  float: left;
  align-items: center;
  justify-content: center;
  padding-left: 1.5vw;
  padding-top: 0.5vh;
  font-family: "Nunito-Black";
  color: #000 !important;
  font-size: 2.2vw;
}

.top-items-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: fit-content;
  padding: 2px;
  cursor: pointer;
  font-family: "Nunito-Black";
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
  border-radius: 5px;
  border-bottom: #000 solid 2px;
  transition: transform 0.3s ease-in-out;
  color: #000;
}

.home-item-container {
  width: fit-content;
  padding: 2px;
  cursor: pointer;
  font-family: "Nunito-Black";
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  color: #000;
}

.top-items-container:hover {
  transform: scale(0.9);
}
