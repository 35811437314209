// /*                               ()_()
//                                  (. .)
// /----------------------------oo0--(_)--0oo-------------------------------------/

// /---   LOADER    LOADER    LOADER    LOADER    LOADER    LOADER    LOADER   ---/

//                                ooo0  0ooo
// /------------------------------(  )--(  )--------------------------------------/
// /*                             (_)    (_)                                     */
.loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  //display: none;

  &:before {
    content: "";
    width: 40px;
    height: 40px;
    border: solid 3px #808080;
    border-top-color: $color3;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: spin 0.7s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
