.auth-form {
  width: 20vw;
  padding: 30px;
  padding-top: 50px;
  margin-left: 0;
  border-radius: 10px;
  background-image: linear-gradient(to bottom, #c9e5f6, #d8ebf7, #fcfcfc);
  box-shadow: 1px 1px 1px 1px #bbbbbb;
  font-family: "Nunito";
  font-size: 15px;
  color: #000;
}

.tabs {
  display: flex;
  flex-direction: row;
}

.tab {
  width: 100%;
  padding: 10px;
  background-color: #c9e5f6;
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0.3vw;
  font-family: "Nunito-Black";
  font-size: 18px;
  background-color: transparent;
  border-bottom: #000 solid 2px;
}

.tab:hover {
  color: #fcfcfc;
  background-color: #b0daf3;
}

.title {
  font-size: 3.5vw;
  margin-bottom: 10px;
  margin-top: 8px;
  color: #041a30;
  font-family: "Nunito-Black";
  text-align: center;
}

.auth-label {
  display: block;
  margin-bottom: 5px;
}

.auth-input {
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Nunito";
  font-size: 16px;
  background-color: transparent;
}

.auth-container {
  position: fixed;
  top: 20%;
  left: 37%;
  width: fit-content;
  height: fit-content;
  overflow: auto;
  background-color: transparent;
  border-radius: 20px;
  pointer-events: fill;
  overflow: hidden;
}

.exit {
  position: absolute;
  top: 0%;
  left: 22vw;
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  font-family: "Nunito-Black";
}

.exit:hover {
  color: #6a868c;
}

.password-input {
  position: relative;
}

.password-input input {
  align-items: center;
  justify-content: center;
}

.password-input .toggle-password-visibility {
  position: absolute;
  top: 40%;
  right: 0vw;
  width: 15px;
  height: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.password-input .toggle-password-visibility.visible {
  background-color: transparent;
}

.password-input .toggle-password-visibility:hover {
  background-color: #d3d2d2;
  border-radius: 5px;
}

.signed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.signed-title {
  margin: auto;
  margin-top: 4vh;
  margin-bottom: 1vh;
  font-size: 4vw;
  font-family: "Nunito-Black";
}

.signed-subt {
  margin: auto;
  margin-top: 1.5vh;
  font-size: 1.5vw;
  font-family: "Nunito";
}

.signed-text {
  margin: auto;
  margin-top: 2vh;
  font-size: 1vw;
  font-family: "Nunito";
}

.logged-form {
  width: 20vw;
  padding: 30px;
  padding-top: 50px;
  margin-left: 0;
  border-radius: 10px;
  background-image: linear-gradient(to bottom, #c9e5f6, #d8ebf7, #fcfcfc);
  box-shadow: 1px 1px 1px 1px #bbbbbb;
  font-family: "Nunito";
  font-size: 15px;
  color: #000;
  text-align: center;
}
