/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    FORMS    FORMS    FORMS     FORMS     FORMS     FORMS     FORMS    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.vintage .form-control {
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 2px rgba(0, 0, 0, 0.2);

  &::-webkit-input-placeholder {
    color: white;
  } /* WebKit */
  &:-moz-placeholder {
    color: white;
  } /* Firefox 18- */
  &::-moz-placeholder {
    color: white;
  } /* Firefox 19+ */
  &:-ms-input-placeholder {
    color: white;
  } /* IE 10+ */
}

.vintage .input-group {
  background: none;

  .input-group-addon {
    color: white;
  }
}

.vintage {
  &.workspace-color1 .ref-title {
    background-color: $workspace-color1;
  }

  &.workspace-color2 .ref-title {
    background-color: $workspace-color2;
  }

  &.workspace-color3 .ref-title {
    background-color: $workspace-color3;
  }

  &.workspace-color4 .ref-title {
    background-color: $workspace-color4;
  }

  &.workspace-color5 .ref-title {
    background-color: $workspace-color5;
  }
}
