body {
  background-color: #fcfcfc;
  overflow-y: hidden;
}

.image-container {
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: initial !important;
}

.image {
  pointer-events: none;
  width: 1428px;
  height: 85%;
  padding-left: 17vw;
}

.components-container {
  position: absolute;
  top: calc(var(--image-top) * 1px);
  left: calc(var(--image-left) * 1px);
  height: calc(var(--image-height) * 1px);
  width: calc(var(--image-width) * 1px);
  display: block;
  min-height: 20px;
  align-items: center;
}
.components-container-full {
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  min-height: 20px;
}

::-webkit-scrollbar {
  width: 0.4vw;
  height: 1vh;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}
