/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/--  BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR  ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist {
  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .bottombar {
        .mass-form-delete {
          &.selected {
            color: $color;
          }
        }
        .create-new-form {
          #add-form {
            span {
              background-color: $color;
            }
          }
        }
        .go-back {
          color: $color;
        }
      }
    }
  }

  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .bottombar {
        .views {
          li {
            a {
              &.active {
                color: $color;
              }
            }
          }
        }
        .mass-form-delete {
          &.selected {
            &:hover {
              color: $color;
            }
          }
        }
        .create-new-form {
          #add-form {
            &:hover {
              span {
                background-color: $color;
              }
            }
          }
        }
      }
    }
  }

  //Basic Colors
  .bottombar {
    background-color: rgba(10, 16, 26, 0.95);
    .views {
      li {
        div {
          color: #808080;
          &:hover {
            color: white;
          }
          &.active {
            color: #ff5e54;
          }
        }
      }
    }
    .mass-form-delete {
      color: #808080;
      &:hover {
        color: white;
      }
    }
    .create-new-form {
      .icon-arrow {
        color: #808080;
      }
      #add-form {
        background-color: rgba(23, 28, 35, 0.3);
        span {
          color: #0a101a;
          box-shadow: 0 4px 0 rgba(0, 0, 0, 0.8);
        }
      }
    }
    .go-back {
      &:hover {
        color: #ffffff;
      }
    }
  }
}
