.create-form {
  display: flex;
  flex-direction: row;
  height: 100%;

  &.box {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 40%;
    border-radius: 10px;
    border: 2.5px solid #2d2d2d;
    background-color: #6c6c6c34;

    &.left {
      position: relative;
      margin-right: 30px;
      margin-left: 50px;
      padding: 3%;
      padding-top: 2%;

      .cf-form-name-input {
        height: 10%;
        width: 100%;
        background-color: transparent;
        color: #2d2d2d;
        border: none;
        text-align: center;
        text-transform: uppercase;
        font-size: 200%;

        &::placeholder {
          text-transform: none;
          font-style: italic;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }

      .cf-form-data {
        margin-top: 10%;
        overflow: hidden;
        overflow-y: scroll;

        .cf-form-data-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 90%;
          span {
            align-self: flex-end;
          }
        }

        .data-array-container {
          border: #2d2d2d solid 1px;
          border-radius: 2%;
          width: 100%;
          height: 100%;
        }

        select {
          border: none;
          background-color: transparent;
          width: 90%;
          padding: 2%;
          width: 100%;
          height: 100%;
          overflow: hidden;
          overflow-y: scroll;

          &:focus-visible {
            outline: none;
          }

          option {
            max-width: 100%;
            background-color: transparent;
            white-space: pre-wrap;
            word-wrap: break-word;
            margin-bottom: 1%;
          }
        }

        .data-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          margin-bottom: 3%;

          .data {
            background-color: #8989893c;
            width: fit-content;
            height: fit-content;
            padding: 1.3%;
            border-radius: 8px;
          }

          span {
            font-size: 22px;

            &:hover {
              transform: scale(1.1);
              transition: all 0.1s ease-out;
            }
          }
        }
      }
    }

    &.right {
      position: relative;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      padding: 2%;

      .cf-tabs {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: -2.5px;
        right: -35px;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        width: 0;
        height: 101%;
        cursor: pointer;

        .cf-tab {
          background-color: #2d2d2d83;
          border: 2.5px solid #2d2d2d;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          height: 50%;
          width: 35px;

          p {
            margin: 5px;
          }

          &.active {
            position: relative;
            background-color: #6c6c6c34;
            width: 40px;
            right: calc(-100% - 5px);
            border-left: dashed;
          }
        }
      }
      .cf-inputs {
        display: flex;
        flex-direction: column;
        position: static;
        margin-top: 5%;
        margin-left: 3%;
        overflow: hidden;
        overflow-y: scroll;

        .cf-inputs-container {
          display: flex;
          flex-direction: column;
        }

        label {
          margin-top: 3%;
          margin-right: 3%;
          width: 80px;
        }

        .tab-data {
          opacity: 0;
        }

        .tab-data.active {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }

        .radio {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          align-items: baseline;
          justify-items: baseline;
          align-self: flex-start;

          label {
            width: 80%;
          }
        }

        .cf-input {
          width: 47%;

          &.check {
            width: 30px;
          }

          &.radio-button {
            width: 10%;
          }
        }
        .mult-checkbox-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .mult-checkbox {
            display: flex;
            flex-direction: row;
            width: 100px;
            max-width: fit-content;
            margin-right: 4%;
          }
        }
      }

      .cf-error-message {
        position: absolute;
        bottom: 2%;
        color: red;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
