/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----     HEADINGS     HEADINGS     HEADINGS      HEADINGS      HEADINGS    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight:  700;
}

h1, .h1 {
  font-size: $h1-size - 8;
  margin: 30px 0;

  small {
    font-size: $h1-size - 14;
    text-transform: uppercase;
  }
}

h2, .h2 {
  font-size: $h2-size - 4;
}

h3, .h3 {
  font-size: $h3-size - 2;
}

h4, .h4 {
  font-size: $h4-size;
}

h5, .h5 {
  font-size: $h5-size;
}

h6, .h6 {
  font-size: $h6-size;
}

.heading-line {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 5px;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: auto;
    background-color: #01BBD4;
  }
}

@media(min-width: 768px) {
  h1, .h1 {
    font-size: $h1-size - 4;
  }

  h2, .h2 {
    font-size: $h2-size - 4;
  }
}

@media(min-width: 992px) {
  h1, .h1 {
    font-size: $h1-size;
  }

  h2, .h2 {
    font-size: $h2-size;
  }
}


