//COLORS
//------------------------------------------------------------------------------

$main-colors: (
  main-color1: #73dddb,
  main-color2: #5ff2a3,
  main-color3: #ccff66,
  main-color4: #ffa86e,
  main-color5: #836dcb,
);

$secondary-colors: (
  secondary-color1: #ff5e54,
  secondary-color2: #ffa600,
  secondary-color3: #33cc33,
  secondary-color4: #4477dd,
  secondary-color5: #ed129a,
);
