@font-face {
  font-family: "GasoekOne";
  src: url("../resources/fonts/GasoekOne-Regular.ttf") format("truetype");
}

.not-found-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  text-align: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 0px;
  font-family: "GasoekOne";
}

.not-found-title {
  font-size: 25vw;
  color: #333;
  padding: 2vw;
  padding-top: 0;
  padding-bottom: 0;
  margin: auto;
  margin-bottom: 0px;
}

.not-found-text {
  font-size: 7vw;
  width: 10vw;
  color: #bbbbbb;
  margin-bottom: 0px;
}

.go-home {
  font-family: "GasoekOne";
  text-decoration: none;
  font-size: 7vw;
  color: #bbbbbb;
  padding: 0;
  margin: 0;
}

.gohome-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
