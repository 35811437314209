/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---  ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX  ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {

  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .item-box {
        .item {
          &:hover {
            border: solid 1px $color;
            background: none;
            box-shadow: inset 1px 1px 0 $color, inset -1px -1px 0 $color;
          }
          .options {
            background-color: rgba($color, .85);
            li {
              a {
                color: lighten($color, 40%);
              }
            }
          }
        }
        &.selectable {
          .item {
            &.selected {
              .body, .footer {
                background-color: $color;
              }
            }
            &:before, &:after {
              border: solid 2px $color;
            }
            .selection-mask {
              &:before, &:after {
                border: solid 2px $color;
              }
            }
          }
        }
      }
    }
  }

  //Basic Colors
  .item-box {
    .item {
      border: solid 1px #F9F6F4;
      background: linear-gradient(to right, #ffffff, #F9F6F4) no-repeat,
      linear-gradient(to bottom, #F9F6F4, #ffffff) no-repeat,
      linear-gradient(to left, #ffffff, #F9F6F4) no-repeat,
      linear-gradient(to top, #F9F6F4, #ffffff) no-repeat;
      background-position: top left, top right, bottom left, top left;
      background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;

      .body {
        background-color: white;

        [class^="icon-"], [class*=" icon-"] {
          color: rgba(0, 0, 0, .05);
        }
      }

      .footer {
        background-color: white;

        span {
          &:first-child {
            box-shadow: inset -1px 0 0 rgba(0,0,0,.2);
          }

          &:last-child {
            box-shadow: inset 1px 0 0 rgba(0,0,0,.2);
          }
        }
      }

      .options {
        li {
          a {
            &:hover {
              color: white;
            }
          }

          &:nth-child(2), &:nth-child(4) {
            border-left: solid 2px rgba(0,0,0,.20);
            box-shadow: inset 1px 0 0 rgba(255,255,255,.40);
          }

          &:nth-child(3), &:nth-child(4) {
            border-top: solid 2px rgba(0,0,0,.20);
            box-shadow: inset 0 1px 0 rgba(255,255,255,.40);
          }

          &:nth-child(4) {
            box-shadow: inset 1px 1px 0 rgba(255,255,255,.40);
          }
        }
      }
    }

    &.selectable {
      .item {
        box-shadow: none;
        background: none;
        border-style: dotted;
        border-color: #B1AEAB;

        &:hover {
          box-shadow: none;
          background: none;
          border-style: dotted;
          border-color: #B1AEAB;
        }

        &.selected {
          color: white;
        }
      }
    }
  }
}