/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----  TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR  ----/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {
  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .topbar {
        .user {
          .dropdown-menu {
            box-shadow: inset 0 3px 0 $color;
            &:before {
              @include triangle-top-right(5px, 5px, 5px, 5px, $color);
            }
          }
        }

        .skin {
          .dropdown-menu {
            box-shadow: inset 0 3px 0 $color;
            &:before {
              @include triangle-top-right(5px, 5px, 5px, 5px, $color);
            }
          }
        }
      }
    }
  }

  //Basic Colors
  .topbar {
    background-color: #0a101a;

    .logo {
      svg {
        .fil0 {
          fill: #999999;
        }
        .fil1 {
          fill: white;
        }
      }
    }

    .user {
      .dropdown-menu {
        background-color: rgba(255, 255, 255, 0.9);
        border: none;
        border-bottom: solid 3px white;

        img {
          background-color: white;
          border: solid 1px white;
        }

        p {
          small {
            color: #666666;
          }
        }

        .dropdown-menu-footer {
          border-top: solid 2px #dbd6d1;
          box-shadow: inset 0 1px 0 white;

          .btn-primary {
            color: white;
          }
        }
      }
    }

    .skin {
      .dropdown-menu {
        background-color: rgba(255, 255, 255, 0.9);
        border: none;
        border-bottom: solid 3px white;

        img {
          background-color: white;
          border: solid 1px white;
        }

        p {
          small {
            color: #666666;
          }
        }

        .dropdown-menu-footer {
          border-top: solid 2px #dbd6d1;
          box-shadow: inset 0 1px 0 white;

          .btn-primary {
            color: white;
          }
        }
      }
    }
  }
}
