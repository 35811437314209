@font-face {
  font-family: "Nunito-Black";
  src: url("../resources/fonts/Nunito-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: url("../resources/fonts/Nunito-Regular.ttf") format("truetype");
}

.elems-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 20vh;
  width: 50vw;
}

.elem {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 18vw;
  height: fit-content;
  padding: 10px;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  border-radius: 20px;
  box-shadow: 2px 2px 50px 10px #bbbbbb;
  background-image: linear-gradient(to bottom, #c9e5f6, #d8ebf7, #deeef85f);
  transition: transform 0.3s ease;
}

.elem:hover {
  transform: scale(1.1);
}

.elemLink {
  text-decoration: none;
  color: #000 !important;
}

.elemLink.active {
  color: #000 !important;
}

.elem-img {
  pointer-events: none;
  height: 10vh;
  width: 10vh;
  align-self: center;
}

.elem-title {
  font-family: "Nunito-Black";
  font-size: 1.5vw;
  text-decoration: none;
  pointer-events: none;
  align-self: center;
  padding: 1vh;
  max-width: min-content;
}

.elem-text {
  font-family: "Nunito";
  font-size: 1.1vw;
  text-align: justify;
  text-decoration: none;
  pointer-events: none;
  align-self: center;
  padding: 1.2vh;
}
