/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----  TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR  ----/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist.light {
  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .topbar {
        .container > .btn {
          &:hover {
            color: $color;
          }
        }
        .user {
          .dropdown-menu {
            box-shadow: inset 0 3px 0 $color;

            &:before {
              @include triangle-top-right(5px, 5px, 5px, 5px, $color);
            }
          }
        }
        .skin {
          .dropdown-menu {
            box-shadow: inset 0 3px 0 $color;

            &:before {
              @include triangle-top-right(5px, 5px, 5px, 5px, $color);
            }
          }
        }
      }
    }
  }

  //Basic Colors
  .topbar {
    background-color: #ffffff;

    .logo {
      svg {
        .fil0 {
          fill: #999999;
        }
        .fil1 {
          fill: #0a101a;
        }
      }
    }

    .container > .btn {
      margin-top: 12px;
      background-color: #ffffff;
      border: solid 2px #d9d4cf;
      color: #0a101a;

      &.btn-badge {
        .badge {
          color: #ffffff;
        }
      }
    }

    .user {
      a {
        span {
          color: black !important;
        }

        &:focus {
          .image-container {
            border: solid 2px #e9e5dc;
          }
        }
      }
    }
    .skin {
      a {
        span {
          color: black !important;
        }
        .dropdown-menu-option {
          color: white;
        }

        &:focus {
          .image-container {
            border: solid 2px #e9e5dc;
          }
        }
      }
    }
  }
}
