/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----     BASE STYLES      BASE STYLES      BASE STYLES      BASE STYLES    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {
  color: #0A101A!important;
  background-color: white;
}