/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---   CONFIG BOX    CONFIG BOX    CONFIG BOX    CONFIG BOX    CONFIG BOX   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist .config-box {
  border-top: solid 2px #12191d;
  box-shadow: inset 0 1px 0 #546067;
  padding: 30px 0;

  .item {
    .or {
      color: #666666;
    }

    img {
      &.selected {
        border: solid 2px white;
      }
    }

    .color {
      &.selected {
        border: solid 2px white;
      }

      //Main colors
      @each $name-color, $color in $main-colors {
        &.#{$name-color} {
          span {
            background-color: $color;
          }
        }
      }

      //Secundary colors
      @each $name-color, $color in $secondary-colors {
        &.#{$name-color} {
          span {
            background-color: $color;
          }
        }
      }
    }
  }

  @media (min-width: 544px) {
    .item {
      width: 400px;
      margin: 0 auto;
    }
  }

  @media (min-width: 992px) {
    .item {
      width: 100%;
    }

    &:nth-child(1) {
      padding: 30px 40px 35px 0;
    }

    &:nth-child(2) {
      border-left: solid 2px #12191d;
      box-shadow: inset 0 1px 0 #546067, inset 1px 0 0 #546067;
      padding: 30px 0 35px 40px;
    }

    &:nth-child(3) {
      border-bottom: solid 1px #546067;
      box-shadow: inset 0 -2px 0 #12191d, inset 0 1px 0 #546067;
      padding: 30px 40px 35px 0;
    }

    &:nth-child(4) {
      border-bottom: solid 1px #546067;
      border-left: solid 2px #12191d;
      padding: 30px 0 35px 40px;
      box-shadow: inset 0 1px 0 #546067, inset 1px 0 0 #546067,
        inset 0 -2px 0 #12191d;
    }
  }
}
