/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---  CONTENT   CONTENT   CONTENT   CONTENT   CONTENT   CONTENT   CONTENT   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.content {
  padding: 0;

  .background {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
  }

  .work-zone {
    position: relative;
    overflow-x: hidden;

    header {
      h1 {
        text-align: center;
        text-transform: uppercase;
      }

      .order-forms {
        text-align: center;
        margin: 0 auto 35px auto;

        a {
          &.dropdown-toggle:after {
            border-top: solid 4px;
            border-right: solid 3px transparent;
            border-left: solid 3px transparent;
          }
        }

        .dropdown-menu {
          min-width: 64px !important;
          width: 64px !important;
          right: 0;
          left: 0;
          border-radius: 0;
          text-align: center;
          margin: 5px auto;

          li {
            cursor: pointer;
          }
        }
      }

      .search-field {
        width: 100%;
        margin-bottom: 30px;
      }

      #reset-styles {
        display: block;
        margin: 0 auto 30px auto;
      }

      #create-new-funcs {
        float: right;
        margin: 30px 4px;
        border-radius: 100px;

        &:hover {
          background-color: rgb(199, 199, 199);
        }
        span {
          font-size: 20px !important;
        }
      }
    }

    h3 {
      position: relative;
      top: -10px;
      font-weight: normal;
      margin-bottom: 10px;
      text-align: center;
    }

    .items-container {
      padding-bottom: 100px;
    }

    .up {
      width: 40px;
      margin: auto;
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 65px;
      font-size: 40px;
      text-align: center;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    .work-zone {
      header {
        h1 {
          text-align: left;
          float: left;
          margin: 35px 0;
        }

        .order-forms {
          float: right;
          margin: 35px 20px 35px 0;

          .dropdown-menu {
            min-width: 64px;
            right: 0;
            left: auto;
          }
        }

        .search-field {
          width: 250px;
          float: right;
          margin: 30px 0;
        }

        #reset-styles {
          float: right;
          margin: 30px 0;
        }

        #create-new-funcs {
          float: right;
          margin: 30px 4px;
          border-radius: 100px;

          &:hover {
            background-color: rgb(199, 199, 199);
          }
          span {
            font-size: 24px !important;
          }
        }
      }

      h3 {
        text-align: left;
      }
    }
  }

  @media (min-width: 992px) {
    .work-zone {
      header {
        h1 {
          margin: 33px 0;
        }
        .search-field {
          width: 400px;
        }
      }
      .items-container-markers {
        width: 570px;
        margin: auto;
        position: relative;
      }
    }
  }

  @media (min-width: 1200px) {
    .work-zone {
      header {
        .search-field {
          width: 472px;
        }
      }
      .items-container-markers {
        width: 776px;
      }
    }
  }
}
