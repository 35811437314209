/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----  TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR    TOPBAR  ----/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.topbar {
  height: 70px;
  width: 100%;
  padding: 5px 0;
  position: fixed;
  top: 0;
  z-index: 200;

  .logo {
    margin-top: 5px;
    position: absolute;

    .fil0 {
      height: 49px;
    }
  }

  .btn {
    float: right;
    margin-top: 15px;
    margin-right: 5px;
    letter-spacing: 1px;
    font-size: 14px;

    // &.btn-badge {
    //   padding-left: 10px;
    //   padding-right: 10px;
    // }

    + .btn {
      margin-right: 10px;
    }
  }

  .user {
    float: right;
    position: relative;

    > a {
      display: block;
      color: white;

      &:after {
        display: none !important;
      }

      &:focus {
        .image-container {
          border: solid 2px #232831;
        }
      }

      .image-container {
        display: inline-block;
        margin-top: -4px;
        margin-left: 8px;
        border-radius: 50%;
        padding: 3px;
        border: solid 2px transparent;

        img {
          width: 51px;
          height: 51px;
          border: solid 1px white;
          background-color: #ffffff;
          border-radius: 50%;
        }
      }
    }

    .dropdown-menu {
      width: 100vw;
      border-radius: 0;
      padding: 0;
      margin-top: 4px;
      left: auto;
      right: -15px;

      &:before {
        right: 40px !important;
      }

      .dropdown-menu-body {
        padding: 15px;

        img {
          width: 94px;
          height: 94px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
        }

        p {
          display: inline-block;
          vertical-align: middle;
          margin-left: 20px;
          font-size: 20px;

          small {
            display: block;
          }
        }
      }

      .dropdown-menu-footer {
        padding: 15px;

        .btn {
          margin: 0;
          float: none;
          width: 110px;
          font-weight: 700;

          &:last-child {
            float: right;
          }
        }
      }
    }
  }

  .skin {
    float: right;
    position: relative;
    top: 35px;

    > a {
      display: block;
      color: white;

      &:after {
        display: none !important;
      }

      &:focus {
        .image-container {
          border: solid 2px #232831;
        }
      }
    }

    .dropdown-menu {
      width: 100vw;
      border-radius: 0;
      padding: 0;
      margin-top: 4px;
      left: auto;
      right: -15px;

      &:before {
        right: 10px !important;
      }

      .dropdown-menu-body {
        display: flex;
        flex-direction: column;

        .dropdown-menu-option {
          width: 100%;
          font-size: 20px;
          padding: 10px;

          img {
            width: 40px;
            height: 40px;
            border-radius: 10%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }
          &:hover {
            background-color: #9f9f9f50;
          }
        }
        img {
          width: 94px;
          height: 94px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
        }

        p {
          display: inline-block;
          vertical-align: middle;
          margin-left: 20px;
          font-size: 20px;

          small {
            display: block;
          }
        }
      }

      .dropdown-menu-footer {
        padding: 15px;
        padding-top: 0;
      }
    }
  }
  @media (min-width: 544px) {
    .btn {
      margin-right: 25px;

      + .btn {
        margin-right: 20px;
      }
    }

    .user {
      .dropdown-menu {
        width: 310px;
        right: -35px;

        &:before {
          right: 59px !important;
        }
      }
    }

    .skin {
      .dropdown-menu {
        width: 200px;
        right: -35px;

        &:before {
          right: 59px !important;
        }
      }
    }
  }

  //  @media (min-width: 768px) {
  //   .btn {
  //     &.btn-badge {
  //       padding-left: 20px;
  //       padding-right: 20px;
  //     }
  //   }
  // }
}
