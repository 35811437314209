/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----   ICONS     ICONS     ICONS     ICONS     ICONS     ICONS     ICONS   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
@font-face {
  font-family: "icons";
  src: url("../../fonts/icons.eot?f73sjv");
  src: url("../../fonts/icons.eot?f73sjv#iefix") format("embedded-opentype"),
    url("../../fonts/icons.ttf?f73sjv") format("truetype"),
    url("../../fonts/icons.woff?f73sjv") format("woff"),
    url("../../fonts/icons.svg?f73sjv#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icon2";
  src: url("../../fonts/iconMoon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="font-cn-"],
[class*=" font-cn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon2" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-cn-upload-cloud:before {
  padding: 3px;
  font-size: 20px !important;
  content: "\e9c3";
  align-items: center;
  display: flex;
}
.font-cn-download:before {
  font-size: 20px !important;
  padding: 3px;
  content: "\e9c2";
}
.font-cn-file:before {
  font-size: 16px !important;
  padding: 3px;
  content: "\e926";
}
.font-cn-clear-file:before {
  font-size: 16px !important;
  padding: 3px;
  content: "\ea0d";
}
.font-cn-search:before {
  font-size: 20px !important;
  content: "\e986";
}
.font-cn-create:before {
  font-size: 20px !important;
  content: "\ea3e";
}
.font-cn-modify:before {
  font-size: 20px !important;
  content: "\e984";
}
.font-cn-delete:before {
  font-size: 20px !important;
  content: "\ea0f";
}
.font-cn-clear:before {
  font-size: 20px !important;
  content: "\ea72";
}
.font-cn-chart:before {
  font-size: 20px !important;
  content: "\e99c";
}
.font-cn-upload:before {
  font-size: 20px !important;
  content: "\e9c8";
}

.icon-home:before {
  content: "\e900";
}
.icon-notification:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-build:before {
  content: "\e903";
}
.icon-edit:before {
  content: "\e904";
}
.icon-copy:before {
  content: "\e905";
}
.icon-delete:before {
  content: "\e906";
}
.icon-date:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e908";
}
.icon-fields:before {
  content: "\e909";
}
.icon-list:before {
  content: "\e90a";
}
.icon-boxes:before {
  content: "\e90b";
}
.icon-carousel:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-arrow:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90f";
}
.icon-chevron-left:before {
  content: "\e910";
}
.icon-chevron-right:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e912";
}
.icon-arrow-right:before {
  content: "\e913";
}
.icon-chevron-up:before {
  content: "\e914";
}
.icon-empty-form:before {
  content: "\e915";
}
.icon-fields-form:before {
  content: "\e916";
}
.icon-config:before {
  content: "\e917";
}
.icon-selected:before {
  content: "\e918";
}
.icon-reset:before {
  content: "\e919";
}
