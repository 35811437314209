/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    BUTTONS    BUTTONS    BUTTONS    BUTTONS    BUTTONS    BUTTONS     ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.vintage .btn {
  color: white;
  background-color: #020407;

  &:hover,
  &:focus {
    color: #73dddb;
  }

  &.btn-primary {
    background-color: #73dddb;
    color: #0a101a;

    &:hover {
      background-color: #ff5e54;
    }
  }

  &.btn-primary-2 {
    background-color: darken(#73dddb, 20%);
    color: white;

    &:hover {
      background-color: darken(#73dddb, 30%);
    }
  }

  &.btn-secondary {
    background-color: #874a4a;
    color: white;

    &:hover {
      background-color: #633636;
    }
  }
}
