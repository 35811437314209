/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---   CONFIG BOX    CONFIG BOX    CONFIG BOX    CONFIG BOX    CONFIG BOX   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.vintage .config-box {
  padding: 0;

  &:last-child {
    .item {
      height: 375px;
    }
  }

  .item {
    background: url(../../images/vintage/config-box.png) no-repeat;
    background-size: 100% 100%;
    height: 255px;
    color: #0a101a;
    padding: 30px 30px 35px 30px;

    h3 {
      margin: 10px 0 10px 0 !important;
    }

    .or {
      color: #808080;
    }

    img {
      &.selected {
        border: solid 2px #0a101a;
      }
    }

    .color {
      &.selected {
        border: solid 2px #0a101a;
      }

      &.main-color1 {
        span {
          background-color: #30aeac;
        }
      }
      &.main-color2 {
        span {
          background-color: #2c9974;
        }
      }
      &.main-color3 {
        span {
          background-color: #993366;
        }
      }
      &.main-color4 {
        span {
          background-color: #cf2013;
        }
      }
      &.main-color5 {
        span {
          background-color: #5636a1;
        }
      }
      &.secondary-color1 {
        span {
          background-color: #ff5e54;
        }
      }
      &.secondary-color2 {
        span {
          background-color: #ffa600;
        }
      }
      &.secondary-color3 {
        span {
          background-color: #33cc33;
        }
      }
      &.secondary-color4 {
        span {
          background-color: #4477dd;
        }
      }
      &.secondary-color5 {
        span {
          background-color: #ed129a;
        }
      }
      &.workspace-color1 {
        span {
          background-color: #c6b186;
        }
      }
      &.workspace-color2 {
        span {
          background-color: #ccac63;
        }
      }
      &.workspace-color3 {
        span {
          background-color: #527974;
        }
      }
      &.workspace-color4 {
        span {
          background-color: #5c6375;
        }
      }
      &.workspace-color5 {
        span {
          background-color: #7d514f;
        }
      }
    }
  }

  @media (min-width: 544px) {
    &:last-child {
      .item {
        height: 220px;
      }
    }

    .item {
      width: 460px;
      margin: 0 auto;
      height: 180px;
    }
  }

  @media (min-width: 768px) {
    .item {
      background-size: calc(100% + 10px) 100%;
    }
  }

  @media (min-width: 992px) {
    .item {
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    &:last-child {
      .item {
        height: 180px;
      }
    }
  }
}
