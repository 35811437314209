/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/--  BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR   BOTTOMBAR  ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {
  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .bottombar {
        .mass-form-delete {
          &.selected {
            color: $color;
          }
        }
        .create-new-form {
          #add-form {
            span {
              background-color: $color;
            }
          }
        }
        .go-back {
          color: $color;
          &:hover {
            color: darken($color, 10%);
          }
        }
      }
    }
  }

  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .bottombar {
        .views {
          li {
            div {
              &.active {
                color: $color;
              }
            }
          }
        }
        .mass-form-delete {
          &.selected {
            &:hover {
              color: $color;
            }
          }
        }
        .create-new-form {
          #add-form {
            &:hover {
              span {
                background-color: $color;
              }
            }
          }
        }
      }
    }
  }

  //Basic colors
  .bottombar {
    background-color: white;
    color: black;
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1);

    .views {
      li {
        div {
          cursor: pointer;
          color: #a8a5a1;

          &:hover {
            color: #6e6d6a;
          }
          &.active {
            color: #ff5e54;
          }
        }
      }
    }

    .mass-form-delete {
      color: #a8a5a1;

      &:hover {
        color: #6e6d6a;
      }
    }

    .create-new-form {
      .icon-arrow {
        color: #a8a5a1;
      }

      #add-form {
        background-color: rgba(255, 255, 255, 0.3);

        span {
          color: white;
          box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
