.terminal-settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 0;
  transition: opacity 0.3s ease-in-out;
}

.terminal-settings {
  position: fixed;
  top: 40%;
  left: 45%;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.exit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
}

.exit-button:hover {
  color: #ee9d34;
}
