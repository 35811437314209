/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    BUTTONS    BUTTONS    BUTTONS    BUTTONS    BUTTONS    BUTTONS     ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.btn {
  border-radius: $btn-radius;
  border: none;
  padding: $btn-padding;
  cursor: pointer;
  letter-spacing: 1px;
  position: relative;

  &.btn-icon {
    padding-top: 3px;
    padding-bottom: 3px;

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 24px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
    &.login {
      background-color: $color3 !important;
      color: black;
    }
  }

  &.btn-badge {
    // padding-left: 20px;
    // padding-right: 20px;

    .badge {
      position: absolute;
      top: -9px;
      right: -9px;
    }
  }
}
