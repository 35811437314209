/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/---  ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX    ITEM BOX  ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.item-box {
  width: 250px;
  height: 156px;
  margin: 0 auto 10px auto;
  padding: 0 15px;
  cursor: pointer;

  .item {
    width: 100%;
    height: 100%;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
    position: relative;

    &:hover {
      .body,
      .footer {
        filter: blur(2px);
      }

      .options {
        display: block;
        border-radius: 3px;
      }
    }

    .body {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      position: relative;
      height: 110px;
      width: 100%;
      display: table;

      .name {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        z-index: 1;
      }

      [class^="icon-"],
      [class*=" icon-"] {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 105px;
      }
    }

    .footer {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      height: 32px;
      padding-top: 3px;
      margin-top: 2px;

      span {
        display: inline-block;
        font-size: 14px;
        width: 50%;
        height: 22px;

        [class^="icon-"],
        [class*=" icon-"] {
          font-size: 30px;
          display: inline-block;
          vertical-align: top;
          position: relative;
          top: -5px;
          margin: 0 -4px 0 -5px;
        }
      }
    }

    .options {
      display: none;
      padding: 0;
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
      margin: auto;

      li {
        display: block;
        float: left;
        width: 50%;
        height: 50%;

        a {
          font-size: 14px;
          display: block;

          &:hover {
            [class^="icon-"],
            [class*=" icon-"] {
              transform: scale(1.1);
            }
          }

          [class^="icon-"],
          [class*=" icon-"] {
            font-size: 30px;
            display: block;
            margin-top: 8px;
          }
        }
      }
    }
  }

  &.selectable {
    .item {
      background: none;
      border-style: dotted;

      &:before,
      &:after {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        top: -2px;
        margin: auto;
        border-bottom: none !important;
      }

      &:before {
        left: -2px;
        border-right: none !important;
        border-radius: 3px 0 0 0;
      }

      &:after {
        right: -2px;
        border-left: none !important;
        border-radius: 0 3px 0 0;
      }

      .body,
      .footer {
        filter: blur(0);
      }

      .options {
        display: none;
      }

      .selection-mask {
        position: absolute;
        z-index: 100;
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
        border-radius: 3px;

        &:before,
        &:after {
          content: "";
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: -7px;
          margin: auto;
          border-top: none !important;
        }

        &:before {
          left: -7px;
          border-right: none !important;
          border-radius: 0 0 0 3px;
        }

        &:after {
          right: -7px;
          border-left: none !important;
          border-radius: 0 0 3px 0;
        }
      }
    }
  }

  @media (min-width: 544px) {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    width: 33.3%;
  }

  @media (min-width: 992px) {
    width: 25%;
  }

  @media (min-width: 1200px) {
    width: 20%;
  }
}
