.ps-console-container {
  display: flex;
  z-index: 1000;
  width: 80%;
  height: fit-content;
  background-color: #dddd;
  justify-content: center;
  border-radius: 4px;
  align-self: center;
  justify-content: center;
  margin: auto;
  line-height: 1.2;

  :root {
    --x: 20px;
    --y: 20px;
  }

  .autocomplete_box {
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px grey;
    color: white;
    background-color: #333235;
    position: fixed;
    width: 150px;
    top: calc(1px * var(--x) + 3vh);
    left: calc(1px * var(--y) + 19vw);
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .autocomplete_box.show {
    opacity: 1;
  }
}
