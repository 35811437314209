#modal-login {
  .modal-dialog {
    .modal-content {
      .modal-body {
        text-align: center;
        width: fit-content;
        margin: auto;

        .login-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: fit-content;

          .title-login {
            font-size: 32px;
          }

          .subtitle-login {
            font-size: 16px;
          }

          .label-login {
            font-size: 15px;
            margin-bottom: 0 !important;
          }

          .input-login {
            margin-bottom: 0.5em;
          }
          a {
            color: blue;
            cursor: pointer;
          }
        }

        .btn {
          &.login {
            margin: 1px;
          }
        }
      }
    }
  }
}
