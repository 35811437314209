/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----   FONTS     FONTS     FONTS     FONTS     FONTS     FONTS     FONTS   ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */

//@import 'https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700';

@font-face {
  font-family: "Roboto Condensed";
  src: url(../../fonts/RobotoCondensed-Regular.ttf);
}
