.act-container{
  position: fixed;
  top: 20%;
  left: 37%;
  width: fit-content;
  height: fit-content;
  overflow: auto;
  background-color: transparent;
  border-radius: 20px;
  pointer-events: fill;
  overflow: hidden;
  box-shadow: 2px 2px 50px 10px #bbbbbb;
  text-align: center;
}
