.message-strip {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  bottom: 40px;
  left: 0;
  height: 40px;
  width: 100%;

  &.error {
    background-image: linear-gradient(
      to top,
      #c30000,
      #c300008f,
      #db020236,
      #ff0e0e00
    );
  }

  &.ok {
    background-image: linear-gradient(
      to top,
      #227415,
      #299f179f,
      #299f174c,
      #ff0e0e00
    );
  }

  &.info {
    background-image: linear-gradient(
      to top,
      #e1bb12,
      #e1bb12bb,
      #e1bb1263,
      #ff0e0e00
    );
  }

  img {
    position: absolute;
    bottom: 10px;
    left: 15px;
    height: 50px;
    width: 50px;
  }
  span {
    padding-left: 85px;
    align-self: baseline;
    font-size: larger;
  }
}
