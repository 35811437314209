/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----    FORMS    FORMS    FORMS     FORMS     FORMS     FORMS     FORMS    ---/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.futurist .form-control {
  background-color: #111218;
  border: solid 1px #111218;
  border-bottom: solid 2px #090b0f;

  &:focus {
    background-color: #111218;
    border-color: #090b0f;
  }
}

.futurist {
  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .input-group {
        background: linear-gradient(
            to right,
            #7b7d83 0%,
            #3e3b44 15%,
            #3e3b44 85%,
            $color 100%
          )
          no-repeat;
        background-position: top left;
        background-size: 100% 3px;

        .input-group-addon {
          color: $color;
        }
      }
    }
  }
}

.futurist.plain {
  .ref-title {
    background-color: #1e232d;
  }
}

.futurist.degraded {
  .ref-title {
    background-color: #25303a;
  }
}
