/*                               ()_()
                                 (. .)
/----------------------------oo0--(_)--0oo-------------------------------------/

/----   ITEM CAROUSEL    ITEM CAROUSEL    ITEM CAROUSEL    ITEM CAROUSEL   ----/

                               ooo0  0ooo
/------------------------------(  )--(  )--------------------------------------/
/*                             (_)    (_)                                     */
.clear {
  //Main colors
  @each $name-color, $color in $main-colors {
    &.#{$name-color} {
      .items-carousel-list {
        .slick-list {
          .item-carousel {
            .item {
              .row {
                .stats {
                  > div {
                    border: solid 1px #e2e1e0;
                    background: linear-gradient(
                          to right,
                          $color 0%,
                          #e2e1e0 50%
                        )
                        no-repeat,
                      linear-gradient(0deg, $color 0%, #e2e1e0 50%) no-repeat,
                      linear-gradient(to right, #e2e1e0 50%, $color 100%)
                        no-repeat,
                      linear-gradient(180deg, $color 0%, #e2e1e0 50%) no-repeat;
                    background-position: top left, top right, bottom left,
                      top left;
                    background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
                  }
                }
              }
              .options {
                background-color: rgba($color, 0.85);
                li {
                  a {
                    color: lighten($color, 40%);
                  }
                }
              }
            }
            &.selectable {
              &.selected {
                .item {
                  background-color: $color !important;

                  .options {
                    background-color: rgba($color, 0.85);
                  }
                }
              }
              &:before,
              &:after {
                border: solid 2px $color;
              }

              .selection-mask {
                &:before,
                &:after {
                  border: solid 2px $color;
                }
              }
            }
          }
        }
        .arrow {
          color: $color;
        }
      }
    }
  }

  //Secundary colors
  @each $name-color, $color in $secondary-colors {
    &.#{$name-color} {
      .items-carousel-list {
        .arrow {
          &:hover {
            color: $color;
          }
          &.next {
            &:after {
              background-color: $color;
            }
          }
          &.prev {
            &:after {
              background-color: $color;
            }
          }
        }
      }
    }
  }

  //Basic Colors
  .items-carousel-list {
    .slick-list {
      .item-carousel {
        border: solid 1px #f9f6f4;
        background: linear-gradient(to right, #ffffff 0%, #f9f6f4 30%) no-repeat,
          linear-gradient(0deg, #ffffff 0%, #f9f6f4 30%) no-repeat,
          linear-gradient(to right, #f9f6f4 70%, #ffffff 100%) no-repeat,
          linear-gradient(180deg, #ffffff 0%, #f9f6f4 30%) no-repeat;
        background-position: top left, top right, bottom left, top left;
        background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;

        .item {
          background-color: white;

          .row {
            .stats {
              > div {
                //border: solid 1px rgba(#FFFFFF, .1);
                background: linear-gradient(
                      to right,
                      #ffffff 0%,
                      rgba(#ffffff, 0.1) 50%
                    )
                    no-repeat,
                  linear-gradient(0deg, #ffffff 0%, rgba(#ffffff, 0.1) 50%)
                    no-repeat,
                  linear-gradient(
                      to right,
                      rgba(#ffffff, 0.1) 50%,
                      #ffffff 100%
                    )
                    no-repeat,
                  linear-gradient(180deg, #ffffff 0%, rgba(#ffffff, 0.1) 50%)
                    no-repeat;
                background-position: top left, top right, bottom left, top left;
                background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
              }
            }
          }

          .options {
            li {
              a {
                &:hover {
                  color: white;
                }
              }

              + li {
                border-left: solid 2px rgba(0, 0, 0, 0.2);
                box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
              }
            }
          }
        }

        &.selectable {
          border-color: #b1aeab;

          &.selected {
            .item {
              color: white;
            }
          }
        }
      }
    }
  }
}
